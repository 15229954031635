import React from 'react'
import SEO from "../../components/theme/seo"
import Layout from "../../components/theme/layout"
import Display from "../../components/display"
import BrochureImg from "../../components/images/printing/brochures"

export default function Brochures() {
    return (
        <>
            <Layout>
                <SEO title="Brochures" />
                <Display
                    title="Brochures"
                    description="From the copywriting to graphics, designs and layout, talk to us about printing your impressive, informative brochures. "
                    prev="/printing/office-stationary"
                    next="/printing/flyers"
                    image={<BrochureImg />}
                />
            </Layout>                
        </>
    )
}
